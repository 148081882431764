// Prepare variables for generation of Bootstrap theme
$font-family-sans-serif:            Lato, "Helvetica Neue", Helvetica, Arial, sans-serif;

$headings-font-weight:              300;

$primary:			                #244264;
$danger:                            #ac3939;
$info:                              lighten($primary, 15%);
$light:                             #dee2e6; // Copied from $gray-300

$enable-rounded:                    false;

$headings-color:                    $primary;
$link-color:                        $info;
$code-color:                        #911c52;

// Results page tabs
$nav-tabs-border-color:             $info;
$nav-tabs-link-hover-border-color:  lighten($primary, 50%) lighten($primary, 50%) $nav-tabs-border-color lighten($primary, 50%);
$nav-tabs-link-active-border-color: $primary $primary white $primary;

$nav-tabs-link-active-color:        $primary;

//Import Bootstrap to generate the theme
@import "~bootstrap/scss/bootstrap";

// Everything below is adaptation outside Bootstrap.

html {
  background-color: lighten($primary, 7.5%);
}

nav.navbar, footer {
  font-weight: $headings-font-weight;
}


.fb-50 {
  flex-basis: 50%;
}

.mh-50 {
  max-height: 50vh;
}
